import {
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import { toDays, toFragment, type DurationFragment } from '@/utils/dateTime';

import { FragmentSelector } from './MaximumRetentionEditor';
import { frequencyMapping } from './MinimumRetentionEditor';

interface RetentionSelectorProps {
  initialRetentionDays?: number;
  initialFragment?: DurationFragment;
  onChange: (retentionInDays: number) => void;
  isInvalidRetention?: boolean;
}

export default function RetentionSelector(props: RetentionSelectorProps) {
  const [selectedFragment, setSelectedFragment] = useState<DurationFragment>(
    props.initialFragment || 'days'
  );

  const fragment =
    frequencyMapping.find(({ unit }) => selectedFragment === unit) ||
    frequencyMapping[0];

  const [selectedRetention, setSelectedRetention] = useState(
    toFragment(
      selectedFragment,
      props.initialRetentionDays || fragment.defaultRetentionInDays
    )
  );

  const calcInDays = (retention: number, fragment: DurationFragment) => {
    return toDays(fragment, retention);
  };

  const onFragmentChange = (newUnit: DurationFragment) => {
    setSelectedFragment(newUnit);
    props.onChange(calcInDays(selectedRetention, newUnit));
  };

  const onRetentionChange = (retention: number) => {
    setSelectedRetention(retention);
    props.onChange(calcInDays(retention, selectedFragment));
  };

  return (
    <Stack gap='12px'>
      <Stack direction='row' alignItems='start' gap='12px'>
        <FrequencyTextField
          initialValue={selectedRetention}
          onChange={onRetentionChange}
          isInvalidRetention={props.isInvalidRetention}
        />
        <FragmentSelector
          fragment={selectedFragment}
          onChange={onFragmentChange}
        />
      </Stack>
      {selectedFragment !== 'days' && (
        <Typography variant='subtitle1'>{`${selectedRetention} ${selectedFragment} will be saved as ${calcInDays(selectedRetention, selectedFragment)} days.`}</Typography>
      )}
    </Stack>
  );
}

const FrequencyTextField = (props: {
  initialValue: number;
  onChange: (retentionInDays: number) => void;
  isInvalidRetention?: boolean;
}) => {
  const [value, setValue] = useState(`${props.initialValue}`);

  useEffect(() => {
    if (value !== `${props.initialValue}`) {
      setValue(`${props.initialValue}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValue]);

  return (
    <TextField
      sx={{
        '& .MuiOutlinedInput-root': {
          paddingRight: '4px',
        },
        '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
          {
            display: 'none',
          },
        '& input[type=number]': {
          MozAppearance: 'textfield',
        },
      }}
      data-testid='retention-input'
      className='w-[100px]'
      type='number'
      inputProps={{
        min: 1,
        step: 1,
        pattern: '[0-9]*',
        inputMode: 'numeric',
      }}
      InputProps={{
        endAdornment: (
          <Stack direction='row' gap='4px' alignItems='center'>
            {props.isInvalidRetention && (
              <InputAdornment position='start'>
                <Tooltip
                  PopperProps={{
                    sx: {
                      width: '230px',
                      padding: '8px 12px',
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'var(--mui-palette-error-main)',
                      },
                    },
                  }}
                  title='Retention period has passed. To save some snapshots, increase the retention time.'
                >
                  <i className='material-symbols-error-outline-rounded bg-[var(--mui-palette-error-main)]' />
                </Tooltip>
              </InputAdornment>
            )}
          </Stack>
        ),
      }}
      variant='outlined'
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        if (e.target.value) {
          props.onChange(+e.target.value);
        }
      }}
      onBlur={() => {
        let numericValue = +value;
        numericValue = isNaN(numericValue) ? 1 : numericValue;
        numericValue = Math.max(1, numericValue);
        setValue(`${numericValue}`);
        props.onChange(numericValue);
      }}
      error={props.isInvalidRetention}
    />
  );
};
